/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';
import './../style/tachyons.min.css';
import sightAssets from '@monkvision/sights';
import {
  ApiUrl,
  getSightByTypeName,
  InspectionStatus,
  MonkCallbackUrl,
} from 'lib';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { InspectionCreateGql } from 'gql';

const FETCH_INSPECTION_POLL_DURATION = 7000;

const Types = ['suv', 'city', 'sedan', 'van', 'minivan', 'pickup', 'crossover'];
// TODO refactor
// TODO store last five tests in localstorage
async function runTest(addVehicle, createInspection, images, setInspectionID) {
  // Create a new inspection
  // create a new vehicle and get the uuid
  setInspectionID(null);
  const v = await addVehicle();

  // create images for inspection
  const inspectionImages = [];
  for (const key in images) {
    if (images[key] === '') {
      continue;
    }
    inspectionImages.push({
      // TODO additional data is not in the inspection when we get it back
      additional_data: {
        id: key,
        category: sightAssets[key].category,
        label: sightAssets[key].label.en,
      },
      name: key,
      acquisition: {
        strategy: 'download_from_url',
        url: images[key],
      },
      tasks: ['damage_detection', 'wheel_analysis'],
    });
  }
  console.log(inspectionImages);

  const inspectionPayload = {
    additional_data: {
      vehicle_uuid: v.data.insert_vehicles_one.uuid,
      vehicle_id: v.data.insert_vehicles_one.id,
    },
    images: inspectionImages,
    tasks: {
      damage_detection: {
        status: InspectionStatus.Todo,
        generate_subimages_damages: { generate_tight: true },
        generate_subimages_parts: { generate_tight: true },
        generate_visual_output: {
          generate_damages: true,
          generate_parts: true,
        },
        callbacks: [{ url: MonkCallbackUrl, headers: {}, params: {} }],
      },
      wheel_analysis: {
        status: InspectionStatus.Todo,
        use_longshots: true,
      },
    },
    damage_severity: {
      output_format: 'default',
    },
  };

  // create inspection in monk
  const mi = await fetch(`${ApiUrl}/inspections`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(inspectionPayload),
  });

  let mij = await mi.json();

  // set the inspection id
  setInspectionID(mij.id);

  // create inspection in drivably
  await createInspection({
    variables: {
      vehicle_id: v.data.insert_vehicles_one.id,
      inspection_id: mij.id,
      status: 'TODO',
    },
  });
}

const TypeButton = ({ type, setType, selected }) => (
  <button
    className={`white pa2 ${selected === type ? 'bg-gray black' : 'bg-blue'}`}
    onClick={() => {
      return setType(type);
    }}
  >
    {type}
  </button>
);

export default function Harness() {
  const [selectedType, setType] = useState('suv');
  const [images, setImage] = useState({});
  const [drivablyInspection, setDrivablyInspection] = useState({});
  const [inspectionJSON, setInspectionJSON] = useState({
    inspection: 'pending',
  });

  //Lazy query to get vehicle images and inspection data
  const [getVehicleImages] = useLazyQuery(
    gql`
      query getInspection($inspectionID: uuid!) {
        inspections(where: { monk_inspection_id: { _eq: $inspectionID } }) {
          monk_inspection_id
          monk_inspection_id
          monk_inspection_status
          vehicle {
            vehicle_images {
              link
              category
              acv_key
              monk_key
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        console.log(data);
        setDrivablyInspection(data.inspections[0]);
      },
    }
  );

  const [inspectionID, setInspectionID] = useState(null);
  const [isInspectionDone, setIsInspectionDone] = useState(false);

  const [createInspection] = useMutation(InspectionCreateGql);
  const [addVehicle] = useMutation(gql`
    mutation {
      insert_vehicles_one(
        object: { make: "Monk Test", source_link: "harness", store_id: 87 }
      ) {
        id
        uuid
      }
    }
  `);

  useEffect(() => {
    console.log(document.querySelectorAll('svg'));
    // Attempt to contain the SVGs to their parent div
    document.querySelectorAll('svg').forEach((svg) => {
      svg.removeAttribute('width');
      svg.removeAttribute('height');
    });
    // TODO persist img urls when type changes
    return setImage([]);
  }, [selectedType]);

  useEffect(() => {
    // new inspection
    if (inspectionID === null) {
      setIsInspectionDone(false);
      setDrivablyInspection({});
      return;
    }

    if (isInspectionDone) {
      return;
    }

    const interval = setInterval(() => {
      // fetch inspection results
      fetch(`${ApiUrl}/inspections/${inspectionID}`, {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.getItem(
            'accessToken'
          )}`,
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })
        .catch((err) => {
          console.log(err);
          setInspectionID(null);
          setInspectionJSON({ inspection: 'error', message: err.message });
        })
        .then((res) => {
          console.log(res);
          return res.json();
        })
        .then((res) => {
          if (res.tasks[0].status === 'DONE') {
            setIsInspectionDone(true);
            setInspectionJSON(res);
            getVehicleImages({
              variables: {
                inspectionID,
              },
            });
          } else {
            setInspectionJSON({ inspection: 'in progress' });
          }
        });
    }, FETCH_INSPECTION_POLL_DURATION);

    return () => clearInterval(interval);
  }, [inspectionID, isInspectionDone]);

  const loaderStyle = `
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  return (
    <div className="flex sans-serif normal pa3 white">
      <style>{loaderStyle}</style>
      <div className="w-100">
        <div className="flex items-center">
          <h1 className="mr3 normal">Testing Harness</h1>
          {inspectionID !== null && !isInspectionDone && (
            <div className="loader"></div>
          )}
        </div>
        <label className="db mv2">Choose vehicle type</label>
        <div className="flex align-center">
          <div>
            {Types.map((type) => (
              <TypeButton
                selected={selectedType}
                type={type}
                setType={setType}
              />
            ))}
          </div>
          <form
            className="flex"
            onSubmit={(e) => {
              e.preventDefault();
              console.log(images);
            }}
          >
            <button
              onClick={() => {
                runTest(addVehicle, createInspection, images, setInspectionID);
              }}
              className="db pa2 ml4 small-caps"
              type="submit"
            >
              test
            </button>
            <button
              onClick={() => {
                let icont = document.querySelectorAll('.monk-image');
                let row = '';
                for (let img of icont) {
                  if (img.style.backgroundImage.includes('undefined')) {
                    row += '\t';
                  } else {
                    row += img.style.backgroundImage.split('"')[1] + '\t';
                  }
                }
                alert(row);
              }}
              className="db pa2 ml4 small-caps"
            >
              export to row
            </button>
          </form>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (e.target[0].value === '') return setImage({});

            setImage((prev) =>
              // reduce to an object with the sight name as the key and the image url as the value
              e.target[0].value.split('\t').reduce((a, img, i) => {
                console.log(img, i);
                return { ...a, [getSightByTypeName(selectedType)[i]]: img };
              }, prev)
            );
          }}
        >
          <label className="db mv2">
            Paste a csv row from{' '}
            <a
              className="link gray"
              href="https://docs.google.com/spreadsheets/d/1psYixz_MDenz88GYsssJAvyejXJ9ijYN1agCnR1QSDM/edit?usp=sharing"
            >
              this google sheet
            </a>
          </label>
          <input
            class="db pv2 w-70"
            type="text"
            placeholder="row from google sheets"
          />

          <button clasName="small-caps" type="submit">
            Apply to sights
          </button>
        </form>
        <div className="flex items-start flex-wrap">
          <div className="flex flex-wrap">
            {getSightByTypeName(selectedType).map((sight) => (
              <div key={sight}>
                <div
                  style={{
                    backgroundImage: `url(${
                      images[sight] || sightAssets[sight].image
                    })`,
                    // shrink images to fit the container
                    backgroundSize: 'contain',
                  }}
                  className="mt3 ma2 w5 pb2 monk-image"
                  dangerouslySetInnerHTML={{
                    __html: sightAssets[sight].overlay,
                  }}
                />
                <p>{sightAssets[sight].label.en}</p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    value={images[sight] || ''}
                    onChange={(e) => {
                      console.log(images);
                      setImage((prev) => ({
                        ...prev,
                        [sight]: e.target.value,
                      }));
                    }}
                    placeholder="image url"
                    className="mb4"
                    type="url"
                  />
                </form>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-50 bg-white">
        <h3 className="black ml2">Clear Car</h3>
        <ReactJson collapsed src={drivablyInspection} />
        <h3 className="black ml2">Monk</h3>
        <ReactJson collapsed src={inspectionJSON} />
      </div>
    </div>
  );
}
